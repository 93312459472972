import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';

const VisitorsList = ({ handleApprove }) => {
  const [visitors, setVisitors] = useState([]);

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
      
        const token = localStorage.getItem('token');
        
        if (!token) {
          throw new Error('No token found');
        }

       
        let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
     

        const response = await fetch(`${apiUrl}/api/users/3`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch visitors');
        }

        const data = await response.json();
        setVisitors(data.map(visitor => ({ ...visitor, disabled: false })));
      } catch (error) {
        console.error('Error fetching visitors:', error);
      }
    };

    fetchVisitors();
  }, []);

  const handleApproval = async (visitor) => {
    try {
        const token = localStorage.getItem('token');
        const adminId = localStorage.getItem('userId');
        const visitorId = visitor._id;

        if (!token || !adminId) {
            throw new Error('Missing token or adminId');
        }

        let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
      

    const response = await fetch(`${apiUrl}/api/promoteVisitor/${adminId}/${visitorId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

        if (!response.ok) {
            throw new Error('Failed to promote visitor to employee');
        }

        setVisitors(visitors.filter(v => v._id !== visitorId));

        // Check if handleApprove is defined
        if (typeof handleApprove === 'function') {
            handleApprove(visitor);
        } else {
            console.warn('handleApprove is not defined');
        }

        // Show success alert with visitor's name
        alert(`Visitor ${visitor.fullName} has been successfully approved as an employee.`);
    } catch (error) {
        console.error('Error promoting visitor:', error);
        alert(`Failed to promote visitor: ${error.message}`);
    }
};

  

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to disable this visitor?");
    if (!confirmDelete) return;
  
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('No token found');
      }
  
     
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
    

    const response = await fetch(`${apiUrl}/api/user/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  
      if (!response.ok) {
        throw new Error('Failed to disable visitor');
      }
  
      
      setVisitors((prevVisitors) => prevVisitors.filter(visitor => visitor._id !== id));
  
    } catch (error) {
      console.error('Error deleting visitor:', error);
    }
  };
  

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Visitors List</h2>

    
      <div className="table-responsive d-none d-md-block">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Skills</th>
              <th>Experience (Years)</th>
              <th>Contact</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {visitors.map((visitor) => (
              <tr key={visitor._id}>
                <td>{visitor.fullName}</td>
                <td>{visitor.professionalDetails?.skills ? visitor.professionalDetails.skills.join(', ') : 'N/A'}</td>
                <td>{visitor.professionalDetails?.expYears}</td>
                <td>{visitor.mobileNo}</td>
                <td>
                  <Link to={`/admin/visitor/${visitor._id}`}>
                    <Button variant="primary" className="me-2 mb-2">
                      View Details
                    </Button>
                  </Link>
                  <Button 
                    variant="success" 
                    className="me-2 mb-2"
                    onClick={() => handleApproval(visitor)}
                  >
                    Approve as Employee
                  </Button>
                  <Button 
                    variant="danger" 
                    className="mb-2"
                    onClick={() => handleDelete(visitor._id)}
                  >
                    Disable
                  </Button>
                </td>
              </tr>                                                    
            ))}
          </tbody>
        </Table>
      </div>

     
      <div className="visitor-cards d-md-none">
        {visitors.map((visitor) => (
          <div key={visitor._id} className="visitor-card mb-3">
            <h5>{visitor.fullName}</h5>
            <p><strong>Skills:</strong> {visitor.professionalDetails?.skills ? visitor.professionalDetails.skills.join(', ') : 'N/A'}</p>
            <p><strong>Experience:</strong> {visitor.professionalDetails?.expYears} years</p>
            <p><strong>Contact:</strong> {visitor.mobileNo}</p>
            <div className="visitor-actions">
              <Link to={`/admin/visitor/${visitor._id}`}>
                <Button variant="primary" className="btn-view me-2 mb-2">
                  View Details
                </Button>
              </Link>
              <Button 
                variant="success" 
                className="mb-2" 
                onClick={() => handleApproval(visitor)}
              >
                Approve as Employee
              </Button>
              <Button 
                variant="warning" 
                className="mb-2" 
                onClick={() => handleDelete(visitor._id)}
              >
                Disable
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VisitorsList;
