import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import styles from '../../VisitorForm/Visitor.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const UpdateEmployeeInfo = () => {
  const { id: employeeId } = useParams(); 
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const [employee, setEmployee] = useState(pageLocation.state?.employee || {});

  const [formData, setFormData] = useState({
    employeeId: employeeId || '',
    fullName: employee.name || '', 
    salary: employee.salary || '',
    designation: employee.designation || '',
    joiningDate: employee.joiningDate
      ? moment(employee.joiningDate).format('YYYY-MM-DD')
      : '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const dataToSubmit = { ...formData, adminId: userId };

    let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
   

    try {
      const response = await fetch(`${apiUrl}/api/employee/UpdateEmployee/${formData.employeeId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSubmit),
      });

      if (response.ok) {
        alert("Employee details updated successfully!");
        navigate('/admin/getEmployeeInfo');
      } else {
        const errorMessage = await response.json();
        console.error("Failed to update employee details:", errorMessage.message);
        alert("Failed to update employee details: " + errorMessage.message);
      }
    } catch (error) {
      console.error('Error updating employee details:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

 
  const handleCancel = () => {
    navigate('/admin/getEmployeeInfo'); 
  };

  return (
    <div className={`${styles.container} mt-5`}>
      <h2 className={`${styles.header} text-center mb-4`}>Update Employee Information</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Control
            type="hidden"
            name="employeeId"
            value={formData.employeeId}
          />

          <Col md={6}>
            <Form.Group controlId="fullName">
              <Form.Label>Full Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="salary">
              <Form.Label>Salary *</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Salary"
                name="salary"
                value={formData.salary}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="designation">
              <Form.Label>Designation *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Designation"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="joiningDate">
              <Form.Label>Joining Date *</Form.Label>
              <Form.Control
                type="date"
                name="joiningDate"
                value={formData.joiningDate}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="text-center mt-4">
          <Button type="submit" variant="primary" className="me-2">
            Submit
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateEmployeeInfo;
