import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import styles from '../../VisitorForm/Visitor.module.css';
import { useParams, useNavigate } from 'react-router-dom';

const EmployeeInfo = () => {
  const { id } = useParams(); 
  const navigate = useNavigate();
  console.log("Employee ID:", id);

  const [formData, setFormData] = useState({
    employeeId: id || '', 
    salary: '',
    designation: '',
    joiningDate: '',
    fullName: '', 
  });

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      const token = localStorage.getItem('token');

      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
    

      try {
        const response = await fetch(`${apiUrl}/api/users/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const employee = await response.json();
          setFormData((prevData) => ({
            ...prevData,
            fullName: employee.fullName, 
          }));
        } else {
          console.error('Failed to fetch employee details');
        }
      } catch (error) {
        console.error('Error fetching employee details:', error);
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const dataToSubmit = { ...formData, adminId: userId };
    
    let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
    
    try {
      const response = await fetch(`${apiUrl}/api/employee/AddEmployee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
        body: JSON.stringify(dataToSubmit),
      });
  
      if (response.ok) {
        alert("Employee details added successfully!");
        navigate('/admin/employees'); 
      } else {
        const errorMessage = await response.json();
        console.error("Failed to add employee details:", errorMessage.message);
        alert("Failed to add employee details: " + errorMessage.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert("An error occurred while adding employee details. Please try again.");
    }
  };

  const handleCancel = () => {
    navigate(-1); 
  };
  
  return (
    <div className={`${styles.container} mt-5`}>
      <h2 className={`${styles.header} text-center mb-4`}>Add Employee Information</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Control
            type="hidden"
            name="employeeId"
            value={formData.employeeId}
          />

          <Col md={6}>
            <Form.Group controlId="fullName">
              <Form.Label>Employee Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="salary">
              <Form.Label>Salary *</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Salary"
                name="salary"
                value={formData.salary}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="designation">
              <Form.Label>Designation *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Designation"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="joiningDate">
              <Form.Label>Joining Date *</Form.Label>
              <Form.Control
                type="date"
                name="joiningDate"
                value={formData.joiningDate}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        
        <Row className="text-center mt-4">
          <Col xs={6} className="d-flex justify-content-center">
            <Button
              type="submit"
              variant="primary"
              className="w-100"
              style={{ maxWidth: '200px' }} 
            >
              Submit
            </Button>
          </Col>
          <Col xs={6} className="d-flex justify-content-center">
            <Button
              variant="secondary"
              className="w-100"
              style={{ maxWidth: '200px' }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EmployeeInfo;
