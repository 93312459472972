import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import styles from '../../VisitorForm/Visitor.module.css';
import { useNavigate } from 'react-router-dom';

const AdditionalDetails = () => {
  const [formData, setFormData] = useState({
    cnic: '',
    emergencyContact: {
      number: '',
      name: '',
      relation: ''
    },
    professionalDetails: {
      linkedIn: '',
      gitHub: '',
      cv: null,
      otherDocs: null,
      expYears: null,
      expSalary: null,
      expJoining: '',
      onSite: false,
      position: '',
      skills: [''], 
      portfolio: [''], 
      refs: [''], 
      questions: '',
      prevJobs: [{ organization: '', designation: '', startDate: '', endDate: '', reasonForLeaving: '' }],
      education: [{ degree: '', institute: '', passingYear: '', grade: '' }],
      relatives: [{ name: '', designation: '', relation: '', contact: '' }],
      bankDetails: [{ bankName: '', branchName: '', accTitle: '', accNo: '', iban: '' }]
    }
  });
  const [cvFile, setCvFile] = useState(null);
  const [isCvSubmitting, setIsCvSubmitting] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [otherDocsFile, setOtherDocsFile] = useState(null);
const [isOtherDocsSubmitting, setIsOtherDocsSubmitting] = useState(false);
const handleOtherDocsFileChange = (e) => setOtherDocsFile(e.target.files[0]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchProfessionalDetails();
  }, []);

  const fetchProfessionalDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
     

      const response = await fetch(`${apiUrl}/api/user/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch professional details');
      }

      const data = await response.json();

      // Update form state with fetched data
      setFormData({
        cnic: data.cnic || '',
        emergencyContact: data.emergencyContact || { number: '', name: '', relation: '' },
        professionalDetails: {
          linkedIn: data.professionalDetails.linkedIn || '',
          gitHub: data.professionalDetails.gitHub || '',
          cv: data.professionalDetails.cv || null,
          otherDocs: data.professionalDetails.otherDocs || null,
          expYears: data.professionalDetails.expYears || null,
          expSalary: data.professionalDetails.expSalary || null,
          expJoining: data.professionalDetails.expJoining || '',
          onSite: data.professionalDetails.onSite || false,
          position: data.professionalDetails.position || '',
          skills: data.professionalDetails.skills || [''],
          portfolio: data.professionalDetails.portfolio || [''],
          refs: data.professionalDetails.refs || [''],
          questions: data.professionalDetails.questions || '',
          prevJobs: data.professionalDetails.prevJobs || [{ organization: '', designation: '', startDate: '', endDate: '', reasonForLeaving: '' }],
          education: data.professionalDetails.education || [{ degree: '', institute: '', passingYear: '', grade: '' }],
          relatives: data.professionalDetails.relatives || [{ name: '', designation: '', relation: '', contact: '' }],
          bankDetails: data.professionalDetails.bankDetails || [{ bankName: '', branchName: '', accTitle: '', accNo: '', iban: '' }],
        }
      });
    } catch (error) {
      console.error('Error fetching professional details:', error);
    }
  };

  const handleFileChange = (e) => setCvFile(e.target.files[0]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleNestedFieldChange = (e, field, index, subField) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      professionalDetails: {
        ...prevData.professionalDetails,
        [field]: prevData.professionalDetails[field].map((item, i) =>
          i === index ? { ...item, [subField]: value } : item
        )
      }
    }));
  };

  const addRelative = () => {
    setFormData((prevData) => ({
      ...prevData,
      professionalDetails: {
        ...prevData.professionalDetails,
        relatives: [...prevData.professionalDetails.relatives, { name: '', designation: '', relation: '', contact: '' }]
      }
    }));
  };

  const addBankDetail = () => {
    setFormData((prevData) => ({
      ...prevData,
      professionalDetails: {
        ...prevData.professionalDetails,
        bankDetails: [...prevData.professionalDetails.bankDetails, { bankName: '', branchName: '', accTitle: '', accNo: '', iban: '' }]
      }
    }));
  };

  const handleCvUpload = async () => {
    if (!cvFile) {
      alert("Please select a CV to upload.");
      return;
    }

    setIsCvSubmitting(true);

    const cvFormData = new FormData();
    cvFormData.append('pdf', cvFile);

    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
     

      const response = await fetch(`${apiUrl}/api/user/cv/upload/${userId}/2`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: cvFormData
      });

      const responseBody = await response.json();
      if (response.ok) {
        setFormData((prevState) => ({
          ...prevState,
          professionalDetails: {
            ...prevState.professionalDetails,
            cv: responseBody.fileName
          }
        }));
        alert("CV uploaded successfully!");
      } else {
        alert("Failed to upload CV: " + (responseBody.message || responseBody));
      }
    } catch (error) {
      console.error("Error uploading CV:", error);
      alert("An error occurred during CV upload.");
    } finally {
      setIsCvSubmitting(false);
    }
  };
  const handleOtherDocsUpload = async () => {
    if (!otherDocsFile) {
      alert("Please select a Last Degree file to upload.");
      return;
    }
  
    setIsOtherDocsSubmitting(true);
  
    const otherDocsFormData = new FormData();
    otherDocsFormData.append('pdf', otherDocsFile);
  
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
      

    const response = await fetch(`${apiUrl}/api/user/otherDocs/upload/${userId}/2`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: otherDocsFormData
    });
      const responseBody = await response.json();
      if (response.ok) {
        setFormData((prevState) => ({
          ...prevState,
          professionalDetails: {
            ...prevState.professionalDetails,
            otherDocs: responseBody.fileName
          }
        }));
        alert("Documents uploaded successfully!");
      } else {
        alert("Failed to upload Last Degree: " + (responseBody.message || responseBody));
      }
    } catch (error) {
      console.error("Error uploading Documents:", error);
      alert("An error occurred during Document upload.");
    } finally {
      setIsOtherDocsSubmitting(false);
    }
  };
  

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isValidCnic = /^[0-9]{13}$/.test(formData.cnic);
    if (!isValidCnic) {
      alert("Invalid CNIC. Please enter a valid 13-digit CNIC number.");
      return;
    }

    setIsFormSubmitting(true);

    const dataToSubmit = { ...formData };

    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
      

    const response = await fetch(`${apiUrl}/api/user/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(dataToSubmit)
    });

      if (response.ok) {
        alert("Details updated successfully!");
        navigate('/employee/employeeData');
      } else {
        const errorMessage = await response.text();
        alert("Failed to update details: " + errorMessage);
      }
    } catch (error) {
      console.error("Error updating details:", error);
      alert("An error occurred while updating. Please try again.");
    } finally {
      setIsFormSubmitting(false);
    }
  };

  return (
    <div className={`${styles.container} mt-5`}>
      <h2 className={`${styles.header} text-center mb-4`}>Additional Employee Information</h2>
      <Form onSubmit={handleFormSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="cnic">
              <Form.Label>CNIC *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter CNIC"
                name="cnic"
                value={formData.cnic}
                onChange={handleFieldChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <h3 className="mt-4">Emergency Contact</h3>
        <Row>
        <Col md={6}>
    <Form.Group controlId="emergencyContactNumber">
      <Form.Label>Contact Number *</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter emergency contact number"
        name="emergencyContact.number"
        value={formData.emergencyContact.number}
        onChange={(e) =>
          setFormData((prevData) => ({
            ...prevData,
            emergencyContact: {
              ...prevData.emergencyContact,
              number: e.target.value
            }
          }))
        }
        pattern="^\d{11}$"
        title="Please enter a valid 11-digit mobile number."
        required
      />
    </Form.Group>
  </Col>
          <Col md={6}>
            <Form.Group controlId="emergencyContactName">
              <Form.Label>Contact Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter emergency contact name"
                name="emergencyContact.name"
                value={formData.emergencyContact.name}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    emergencyContact: {
                      ...prevData.emergencyContact,
                      name: e.target.value
                    }
                  }))
                }
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="emergencyContactRelation">
              <Form.Label>Relation *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter relation"
                name="emergencyContact.relation"
                value={formData.emergencyContact.relation}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    emergencyContact: {
                      ...prevData.emergencyContact,
                      relation: e.target.value
                    }
                  }))
                }
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <h3 className="mt-4">Relatives</h3>
        {formData.professionalDetails.relatives.map((relative, index) => (
  <Row key={index}>
    <Col md={6}>
      <Form.Group controlId={`relativeName-${index}`}>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter relative's name"
          value={relative.name}
          onChange={(e) => handleNestedFieldChange(e, 'relatives', index, 'name')}
          required
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`relativeContact-${index}`}>
        <Form.Label>Contact *</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter contact number"
          value={relative.contact}
          onChange={(e) => handleNestedFieldChange(e, 'relatives', index, 'contact')}
          pattern="^\d{11}$"
          title="Please enter a valid 11-digit mobile number."
          required
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`relativeRelation-${index}`}>
        <Form.Label>Relation *</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter relation"
          value={relative.relation}
          onChange={(e) => handleNestedFieldChange(e, 'relatives', index, 'relation')}
          required
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`relativeDesignation-${index}`}>
        <Form.Label>Designation</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter designation"
          value={relative.designation}
          onChange={(e) => handleNestedFieldChange(e, 'relatives', index, 'designation')}
        />
      </Form.Group>
    </Col>
  </Row>
))}

        <Button type="button" variant="secondary" onClick={addRelative} className="mt-2">
          Add Relative
        </Button>

        <h3 className="mt-4">Bank Details</h3>
        {formData.professionalDetails.bankDetails.map((bank, index) => (
  <Row key={index}>
    <Col md={6}>
      <Form.Group controlId={`bankName-${index}`}>
        <Form.Label>Bank Name *</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter bank name"
          value={bank.bankName}
          onChange={(e) => handleNestedFieldChange(e, 'bankDetails', index, 'bankName')}
          required
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`branchName-${index}`}>
        <Form.Label>Branch Name *</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter branch name"
          value={bank.branchName}
          onChange={(e) => handleNestedFieldChange(e, 'bankDetails', index, 'branchName')}
          required
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`accTitle-${index}`}>
        <Form.Label>Account Title *</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter account title"
          value={bank.accTitle}
          onChange={(e) => handleNestedFieldChange(e, 'bankDetails', index, 'accTitle')}
          required
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`accNo-${index}`}>
        <Form.Label>Account Number *</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter account number"
          value={bank.accNo}
          onChange={(e) => handleNestedFieldChange(e, 'bankDetails', index, 'accNo')}
          required
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`iban-${index}`}>
        <Form.Label>IBAN *</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter IBAN"
          value={bank.iban}
          onChange={(e) => handleNestedFieldChange(e, 'bankDetails', index, 'iban')}
          required
        />
      </Form.Group>
    </Col>
  </Row>

        ))}
        <Button type="button" variant="secondary" onClick={addBankDetail} className="mt-2">
          Add Bank Detail
        </Button>

        <h3 className="mt-4">Upload CV</h3>
        <Row>
          <Col md={6}>
            <Form.Group controlId="cvUpload">
              <Form.Label>Upload CV (PDF)</Form.Label>
              <Form.Control type="file" accept=".pdf" onChange={handleFileChange} />
            </Form.Group>
          </Col>
          <Col md={6} className="d-flex align-items-center">
            <Button variant="secondary" onClick={handleCvUpload} disabled={isCvSubmitting}>
              {isCvSubmitting ? 'Uploading...' : 'Upload CV'}
            </Button>
          </Col>
        </Row>

        <h3 className="mt-4">Upload Documents PDF File</h3>
<Row>
  <Col md={6}>
    <Form.Group controlId="otherDocsUpload">
      <Form.Label>Upload CNIC Image, Last Degree Scan and Experience Letters in file (PDF)</Form.Label>
      <Form.Control type="file" accept=".pdf" onChange={handleOtherDocsFileChange} />
    </Form.Group>
  </Col>
  <Col md={6} className="d-flex align-items-center">
    <Button variant="secondary" onClick={handleOtherDocsUpload} disabled={isOtherDocsSubmitting}>
      {isOtherDocsSubmitting ? 'Uploading...' : 'Upload Other Documents'}
    </Button>
  </Col>
</Row>


        {/* Submit button for all other fields */}
        <div className="text-center">
          <Button type="submit" className="mt-4" variant="primary" disabled={isFormSubmitting}>
            {isFormSubmitting ? 'Submitting...' : 'Submit All Details'}
          </Button>
          <Button type="button" onClick={() => navigate(-1)} className="mt-4 ms-2" variant="secondary">Cancel</Button>
           {/* <Button variant="secondary" onClick={() => navigate(-1)}>Go Back</Button> */}
        </div>
      </Form>
    </div>
  );
};

export default AdditionalDetails;
