import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate, useNavigate } from 'react-router-dom';
import './App.css';

import LoginForm from './Components/LoginForm/LoginForm';
import JobApplicationForm from './Components/VisitorForm/Visitor';
import ProtectedRoute from './Components/ProtectedRoute';

// Import Admin Components
import VisitorsList from './Components/AdminPortal/VisitorsList/VisitorsList';
import VisitorsDetails from './Components/AdminPortal/VisitorsDetails/VisitorsDetails';
import EmployeesList from './Components/AdminPortal/EmployeesList/EmployeesList';
import EmployeeDetails from './Components/AdminPortal/EmployeeDetails/EmployeeDetails';

// Import Employee Portal Components
import EmployeeData from './Components/EmployeePortal/EmployeeData/EmployeeData';
import AdditionalDetails from './Components/EmployeePortal/AdditionalDetails/AdditionalDetails';
import UpdateEmployee from './Components/EmployeePortal/UpdateEmployee/UpdateEmployee';
import EmployeeInfo from './Components/AdminPortal/EmployeeInfo/EmployeeInfo';
import GetEmployeeInfo from './Components/AdminPortal/GetEmployeeInfo/GetEmployeeInfo';
import UpdateEmployeeInfo from './Components/AdminPortal/UpdateEmployeeInfo/UpdateEmployeeInfo';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/login');
  };

  const isFullScreenFormRoute = location.pathname === '/JobApplicationForm' || location.pathname === '/login';
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isEmployeeRoute = location.pathname.startsWith('/employee');

  return (
    <div className="App">
      {!isFullScreenFormRoute && (isAdminRoute || isEmployeeRoute) && (
        <>
          <header className="admin-header d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <h1 className="admin-title">{isAdminRoute ? 'Admin Portal' : 'Employee Portal'}</h1>
            </div>
            <div>
              <button onClick={handleLogout} className="btn btn-primary logout-btn">Logout</button>
            </div>
          </header>

          <div className="dashboard">
            {/* Sidebar */}
            {(isAdminRoute || isEmployeeRoute) && (
              <div className="sidebar">
                <nav className="nav flex-column">
                  {isAdminRoute ? (
                    <>
                      <Link className="nav-link active" to="/admin/visitors">Visitors</Link>
                      <Link className="nav-link" to="/admin/employees">Employees</Link>
                      <Link className="nav-link" to="/admin/getEmployeeInfo">Employees Company Related Information</Link>
                    </>
                  ) : (
                    <>
                      <Link className="nav-link active" to="/employee/employeeData">Employee Data</Link>
                      <Link className="nav-link" to="/employee/additionalDetails">Additional Details</Link>
                   
                  
                    </>
                  )}
                </nav>
              </div>
            )}

            {/* Main Content */}
            <div className="content">
              <Routes>
                {isAdminRoute && (
                  <>
                    <Route path="/admin/visitors" element={<ProtectedRoute><VisitorsList /></ProtectedRoute>} />
                    <Route path="/admin/getEmployeeInfo" element={<ProtectedRoute><GetEmployeeInfo /></ProtectedRoute>} />
                    <Route path="/admin/employees" element={<ProtectedRoute><EmployeesList /></ProtectedRoute>} />
                    <Route path="/admin/visitor/:id" element={<ProtectedRoute><VisitorsDetails /></ProtectedRoute>} />
                    <Route path="/admin/employeeDetails/:id" element={<ProtectedRoute><EmployeeDetails /></ProtectedRoute>} />
                    <Route path="/admin/addEmployee/:id" element={<ProtectedRoute><EmployeeInfo /></ProtectedRoute>} />
                    <Route path="/admin/updateEmployee/:id" element={<ProtectedRoute><UpdateEmployeeInfo /></ProtectedRoute>} />
              
                  </>
                )}

                {isEmployeeRoute && (
                  <>
                    <Route path="/employee/employeeData" element={<ProtectedRoute><EmployeeData /></ProtectedRoute>} />
                    <Route path="/employee/additionalDetails" element={<ProtectedRoute><AdditionalDetails /></ProtectedRoute>} />
                    <Route path="/employee/update/:id" element={<ProtectedRoute><UpdateEmployee /></ProtectedRoute>} />
                  </>
                )}

                <Route path="/login" element={<LoginForm />} />
                <Route path="/JobApplicationForm" element={<JobApplicationForm />} />
              </Routes>
            </div>
          </div>
        </>
      )}

      {isFullScreenFormRoute && (
        <div className="full-screen-form">
          <Routes>
            <Route path='/JobApplicationForm' element={<JobApplicationForm />} />
            <Route path='/login' element={<LoginForm />} />
          </Routes>
        </div>
      )}

      {location.pathname === '/' && <Navigate to="/login" replace />}
    </div>
  );
}

export default App;
