import React, { useEffect, useState } from 'react'; 
import { useNavigate, Link } from 'react-router-dom';
import { Table, Button, Modal, Form } from 'react-bootstrap';

const GetEmployeeInfo = () => {
  const [employees, setEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [leavingDate, setLeavingDate] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem('token');
        const adminId = localStorage.getItem('userId');

        if (!token || !adminId) {
          throw new Error('No token or admin ID found');
        }
        let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
       
        const response = await fetch(`${apiUrl}/api/employee/GetAllEmployees/${adminId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch employees');
        }

        const data = await response.json();
        console.log('Fetched employees:', data);
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, []);

  const handleUpdate = (employee) => {
    navigate(`/admin/updateEmployee/${employee.id}`, { state: { employee } });
  };

  const handleDeleteClick = (employee) => {
    setSelectedEmployee(employee);
    setShowModal(true);
  };

  const handleDelete = async () => {
    if (!selectedEmployee || !leavingDate) return;

    const token = localStorage.getItem('token');
    const adminId = localStorage.getItem('userId');
    let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
   
    try {
      const response = await fetch(`${apiUrl}/api/employee/DeleteEmployee/${selectedEmployee.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          adminId,
          leavingDate,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete employee');
      }

      const data = await response.json();
      console.log(data.message);

      setEmployees((prevEmployees) => prevEmployees.filter(emp => emp.id !== selectedEmployee.id));
      setShowModal(false);
      setLeavingDate('');
      setSelectedEmployee(null);
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Employees Company Related Data</h2>

      <div className="table-responsive d-none d-md-block">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Salary</th>
              <th>Designation</th>
              <th>Joining Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr key={employee.id}>
                <td>{employee.name}</td>
                <td>{employee.salary}</td>
                <td>{employee.designation}</td>
                <td>{new Date(employee.joiningDate).toLocaleDateString()}</td>
                <td className="actions-cell">
                  <Button variant="primary" onClick={() => handleUpdate(employee)}>Update</Button>
                  <Button variant="danger" className="ms-2" onClick={() => handleDeleteClick(employee)}>Delete</Button> {/* Added spacing */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="visitor-cards d-md-none">
        {employees.map((employee) => (
          <div key={employee.id} className="visitor-card mb-3">
            <h5>{employee.name}</h5>
            <p><strong>Salary:</strong> {employee.salary}</p>
            <p><strong>Designation:</strong> {employee.designation}</p>
            <p><strong>Joining Date:</strong> {new Date(employee.joiningDate).toLocaleDateString()}</p>
            <div className="visitor-actions">
            <Button variant="primary" onClick={() => handleUpdate(employee)}>Update</Button>
            <Button variant="danger" className="ms-2" onClick={() => handleDeleteClick(employee)}>Delete</Button> {/* Added spacing */}
            </div>
          </div>
        ))}
      </div>

    
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete {selectedEmployee?.name}? Please enter the leaving date:</p>
          <Form>
            <Form.Group controlId="leavingDate">
              <Form.Label>Leaving Date</Form.Label>
              <Form.Control
                type="date"
                value={leavingDate}
                onChange={(e) => setLeavingDate(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GetEmployeeInfo;
