import React, { useState } from "react"; 
import { Form, Button, Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../../VisitorForm/Visitor.module.css';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

function UpdateEmployee() {
    const { id } = useParams();
    const pageLocation = useLocation();
    const [employee, setEmployee] = useState(pageLocation.state?.employee || null);
    const navigate = useNavigate(); // Initialize useNavigate
    const [formData, setFormData] = useState({
      fullName: employee.fullName,
      fatherName: employee.fatherName,
      email: employee.email,
      password: employee.password,
      mobileNo: employee.mobileNo,
      cnic: employee.cnic || '',  // New field for CNIC
      emergencyContact: {
        number: employee.emergencyContact?.number || '',
        name: employee.emergencyContact?.name || '',
        relation: employee.emergencyContact?.relation || '',
      },
      addresses: {
        current: employee.addresses?.current,
        permanent: employee.addresses?.permanent,
      },
      dob: moment(employee.dob).format('YYYY-MM-DD'),
      personalDetails: {
        gender: employee.personalDetails?.gender,
        maritalStatus: employee.personalDetails?.maritalStatus,
        religion: employee.personalDetails?.religion,
        bloodGroup: employee.personalDetails?.bloodGroup,
      },
      isActive: true,
      professionalDetails: {
        linkedIn: employee.professionalDetails?.linkedIn,
        gitHub: employee.professionalDetails?.gitHub,
        cv: employee.professionalDetails?.cv,
        expYears: employee.professionalDetails?.expYears,
        prevJobs: employee.professionalDetails?.prevJobs?.length > 0
          ? employee.professionalDetails.prevJobs.map(job => ({
              organization: job.organization,
              designation: job.designation,
              startDate: moment(job.startDate).format('YYYY-MM-DD'),
              endDate: moment(job.endDate).format('YYYY-MM-DD'),
              reasonForLeaving: job.reasonForLeaving,
            }))
          : [{}],
        education: employee.professionalDetails?.education?.length > 0
          ? employee.professionalDetails.education.map(edu => ({
              degree: edu.degree,
              institute: edu.institute,
              passingYear: moment(edu.passingYear).format('YYYY-MM-DD'),
              grade: edu.grade,
            }))
          : [{}],
        relatives: employee.professionalDetails?.relatives?.length > 0
          ? employee.professionalDetails.relatives.map(relative => ({
              name: relative.name,
              designation: relative.designation,
              relation: relative.relation,
              contact: relative.contact,
            }))
          : [{ name: '', designation: '', relation: '', contact: '' }],
        bankDetails: employee.professionalDetails?.bankDetails?.length > 0
          ? employee.professionalDetails.bankDetails.map(bank => ({
              bankName: bank.bankName,
              branchName: bank.branchName,
              accTitle: bank.accTitle,
              accNo: bank.accNo,
              iban: bank.iban,
            }))
          : [{ bankName: '', branchName: '', accTitle: '', accNo: '', iban: '' }],
        expSalary: employee.professionalDetails?.expSalary || null,
        expJoining: employee.professionalDetails?.expJoining
          ? moment(employee.professionalDetails.expJoining).format('YYYY-MM-DD')
          : null,
        onSite: employee.professionalDetails?.onSite === true,
        position: employee.professionalDetails?.position || '',
        skills: employee.professionalDetails?.skills || [],
        portfolio: employee.professionalDetails?.portfolio || [],
        refs: employee.professionalDetails?.refs || [],
        questions: employee.professionalDetails?.questions || '',
      },
    });
    
    
  
    const [cv, setCV] = useState(null);  // Separate state for CV file
    const [emailError, setEmailError] = useState('');

    const YES = 'true'; // Constants for better readability
    const NO = 'false';

    const handleChange = (e) => {
      const { name, value, type, files } = e.target;
  
      if (name === 'cv' && type === 'file') {
          setCV(files[0]);
          return;
      }
  
      // Handle address fields
      if (name === 'current' || name === 'permanent') {
          setFormData((prevState) => ({
              ...prevState,
              addresses: {
                  ...prevState.addresses,
                  [name]: value,
              },
          }));
          return;
      }
  
      // Handle professional details fields
      if (['linkedIn', 'gitHub', 'expYears', 'expSalary', 'expJoining', 'position', 'skills', 'portfolio', 'refs', 'questions'].includes(name)) {
          setFormData((prevState) => ({
              ...prevState,
              professionalDetails: {
                  ...prevState.professionalDetails,
                  [name]: value,
              },
          }));
          return;
      }
  
      // Handle onSite boolean
      if (name === 'onSite') {
          setFormData((prevState) => ({
              ...prevState,
              professionalDetails: {
                  ...prevState.professionalDetails,
                  onSite: value === 'true', 
              },
          }));
          return;
      }
  
     
      if (name.startsWith('personalDetails.')) {
          const fieldName = name.split('.')[1];
          setFormData((prevState) => ({
              ...prevState,
              personalDetails: {
                  ...prevState.personalDetails,
                  [fieldName]: value,
              },
          }));
          return;
      }
  
    
      if (name.startsWith('emergencyContact.')) {
          const fieldName = name.split('.')[1];
          setFormData((prevState) => ({
              ...prevState,
              emergencyContact: {
                  ...prevState.emergencyContact,
                  [fieldName]: value,
              },
          }));
          return;
      }
  
     
      setFormData((prevState) => ({
          ...prevState,
          [name]: value,
      }));
  };
  

    const handleArrayChange = (index, fieldName, value, arrayName) => {
      setFormData((prevState) => {
          const currentArray = prevState.professionalDetails[arrayName] || [];
          const updatedArray = currentArray.map((item, i) => {
              if (i === index) {
                  return {
                      ...item,
                      [fieldName]: value.toString(), 
                  };
              }
              return item;
          });
          return {
              ...prevState,
              professionalDetails: {
                  ...prevState.professionalDetails,
                  [arrayName]: updatedArray,
              },
          };
      });
  };
  
  
  

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (emailError) {
            alert("Please correct the errors before submitting.");
            return;
        }

        try {
            const token = localStorage.getItem('token');
            let uploadedCVPath = null;

            console.log("Form Data:", formData);

            if (cv) {
                const formDataForCV = new FormData();
                formDataForCV.append('pdf', cv);

                let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
               

                const cvResponse = await fetch(`${apiUrl}/api/user/cv/upload/3`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    body: formDataForCV,
                });

                if (cvResponse.ok) {
                    const cvResult = await cvResponse.json();
                    uploadedCVPath = cvResult.filePath;
                    console.log("CV uploaded successfully:", uploadedCVPath);
                } else {
                    const errorMessage = await cvResponse.text();
                    console.error("Failed to upload CV:", errorMessage);
                    alert("Failed to upload CV: " + errorMessage);
                    return;
                }
            }
         

          const dataToSubmit = {
            ...formData,
            professionalDetails: {
                ...formData.professionalDetails,
                cv: uploadedCVPath,
            },
        };

        console.log("Data to Submit:", dataToSubmit);
        let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
      

        const response = await fetch(`${apiUrl}/api/user/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(dataToSubmit),
        });


            if (response.ok) {
                alert("Application submitted successfully!");
                navigate("/employee/employeeData"); 
              
            } else {
                const errorMessage = await response.text();
                console.error("Failed to submit application:", errorMessage);
                alert("Failed to submit application: " + errorMessage);
            }
        } catch (error) {
            console.error("An error occurred while updating the application:", error);
            alert("An error occurred while updating the application. Please try again.");
        }
    };
     
     const handleCancel = () => {
        navigate(-1); 
    };
  
  return (
    <div className={`${styles.container} mt-5`}>
      <h2 className={`${styles.header} text-center mb-4`}>Update Employee Basic Information</h2>
      <Form onSubmit={handleSubmit}>
        {/* Personal Information */}
        <Row>
          <Col md={6}>
            <Form.Group controlId="fullName">
              <Form.Label>Full Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="fatherName">
              <Form.Label>Father's Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your father's name"
                name="fatherName"
                value={formData.fatherName}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Email *</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                isInvalid={!!emailError}
                required
              />
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="mobileNo">
              <Form.Label>Mobile No *</Form.Label>
              <Form.Control
                type="text"
                pattern="\d{11}"
                minLength="11"
                maxLength="11"
                placeholder="Enter your 11-digit mobile number"
                name="mobileNo"
                value={formData.mobileNo}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
    <Form.Group controlId="cnic">
      <Form.Label>CNIC</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter CNIC"
        name="cnic"
        value={formData.cnic}
        onChange={handleChange}
      />
    </Form.Group>
  </Col>
        </Row>
        

        <Row>
        <Col md={6}>
          <Form.Group controlId="currentAddress">
            <Form.Label>Current Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter your current address"
              name="current" // Simplified name
              value={formData.addresses.current}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group controlId="permanentAddress">
            <Form.Label>Permanent Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter your permanent address"
              name="permanent" // Simplified name
              value={formData.addresses.permanent}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
          <Col md={6}>
            <Form.Group controlId="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Personal Details */}
         <h4 className="mt-4">Personal Details</h4>
        <Row>
          <Col md={3}>
            <Form.Group controlId="gender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                type="text"
                placeholder="Gender"
                name="personalDetails.gender"
                value={formData.personalDetails.gender}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="maritalStatus">
              <Form.Label>Marital Status</Form.Label>
              <Form.Control
                type="text"
                placeholder="Marital Status"
                name="personalDetails.maritalStatus"
                value={formData.personalDetails.maritalStatus}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="religion">
              <Form.Label>Religion</Form.Label>
              <Form.Control
                type="text"
                placeholder="Religion"
                name="personalDetails.religion"
                value={formData.personalDetails.religion}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
  
          <Col md={3}>
            <Form.Group controlId="bloodGroup">
              <Form.Label>Blood Group</Form.Label>
              <Form.Control
                type="text"
                placeholder="Blood Group"
                name="personalDetails.bloodGroup"
                value={formData.personalDetails.bloodGroup}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <h4 className="mt-4">Professional Details</h4>
<Row>
  <Col md={6}>
    <Form.Group controlId="linkedIn">
      <Form.Label>LinkedIn</Form.Label>
      <Form.Control
        type="text"
        placeholder="LinkedIn Profile"
        name="linkedIn" // Change the name
        value={formData.professionalDetails.linkedIn}
        onChange={handleChange}
      />
    </Form.Group>
  </Col>
  <Col md={6}>
    <Form.Group controlId="gitHub">
      <Form.Label>GitHub</Form.Label>
      <Form.Control
        type="text"
        placeholder="GitHub Profile"
        name="gitHub" // Change the name
        value={formData.professionalDetails.gitHub}
        onChange={handleChange}
      />
    </Form.Group>
  </Col>
  <Col md={6}>
    <Form.Group controlId="expYears">
      <Form.Label>Experience (in years)</Form.Label>
      <Form.Control
        type="number" // Change type to number
        placeholder="Enter years of experience"
        name="expYears" // Change the name
        value={formData.professionalDetails.expYears}
        onChange={handleChange}
        min="0" // Optional: restrict to non-negative numbers
      />
    </Form.Group>
  </Col>
 
</Row>


   {/* Previous Jobs */}
<h4 className="mt-4">Previous Jobs</h4>

<Row>
  <Col md={6}>
    <Form.Group controlId="organization">
      <Form.Label>Previous Organization</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter previous organization"
        name="organization"
        value={formData.professionalDetails.prevJobs?.[0]?.organization || ''}
        onChange={(e) => handleArrayChange(0, 'organization', e.target.value, 'prevJobs')}
      />
    </Form.Group>
  </Col>
  <Col md={6}>
    <Form.Group controlId="designation">
      <Form.Label>Designation</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter your designation"
        name="designation"
        value={formData.professionalDetails.prevJobs?.[0]?.designation || ''}
        onChange={(e) => handleArrayChange(0, 'designation', e.target.value, 'prevJobs')}
      />
    </Form.Group>
  </Col>
</Row>
<Row>
  <Col md={6}>
    <Form.Group controlId="startDate">
      <Form.Label>Start Date</Form.Label>
      <Form.Control
        type="date"
        name="startDate"
        value={formData.professionalDetails.prevJobs?.[0]?.startDate || ''}
        onChange={(e) => handleArrayChange(0, 'startDate', e.target.value, 'prevJobs')}
      />
    </Form.Group>
  </Col>
  <Col md={6}>
    <Form.Group controlId="endDate">
      <Form.Label>End Date</Form.Label>
      <Form.Control
        type="date"
        name="endDate"
        value={formData.professionalDetails.prevJobs?.[0]?.endDate || ''}
        onChange={(e) => handleArrayChange(0, 'endDate', e.target.value, 'prevJobs')}
      />
    </Form.Group>
  </Col>
  <Col md={6}>
    <Form.Group controlId="reasonForLeaving">
      <Form.Label>Reason for Leaving</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter reason for leaving"
        name="reasonForLeaving"
        value={formData.professionalDetails.prevJobs?.[0]?.reasonForLeaving || ''}
        onChange={(e) => handleArrayChange(0, 'reasonForLeaving', e.target.value, 'prevJobs')}
      />
    </Form.Group>
  </Col>
</Row>




{/* Education Section */}
<h4 className="mt-4">Education</h4>
<Row>
  <Col md={6}>
    <Form.Group controlId="degree">
      <Form.Label>Degree</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter degree"
        name="degree"
        value={formData.professionalDetails.education?.[0]?.degree || ''}
        onChange={(e) => handleArrayChange(0, 'degree', e.target.value, 'education')}
      />
    </Form.Group>
  </Col>
  <Col md={6}>
    <Form.Group controlId="institute">
      <Form.Label>Institute</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter institute"
        name="institute"
        value={formData.professionalDetails.education?.[0]?.institute || ''}
        onChange={(e) => handleArrayChange(0, 'institute', e.target.value, 'education')}
      />
    </Form.Group>
  </Col>
</Row>
<Row>
  <Col md={6}>
    <Form.Group controlId="passingYear">
      <Form.Label>Passing Year</Form.Label>
      <Form.Control
        type="date"
        name="passingYear"
        value={formData.professionalDetails.education?.[0]?.passingYear || ''}
        onChange={(e) => handleArrayChange(0, 'passingYear', e.target.value, 'education')}
      />
    </Form.Group>
  </Col>
  <Col md={6}>
    <Form.Group controlId="grade">
      <Form.Label>Grade</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter grade"
        name="grade"
        value={formData.professionalDetails.education?.[0]?.grade || ''}
        onChange={(e) => handleArrayChange(0, 'grade', e.target.value, 'education')}
      />
    </Form.Group>
  </Col>
</Row>
<h4 className="mt-4">Emergency Contact</h4>
<Row>
    <Col md={4}>
        <Form.Group controlId="emergencyContactNumber">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
                type="text"
                placeholder="Enter emergency contact number"
                name="emergencyContact.number"
                value={formData.emergencyContact?.number || ''} // Ensure it doesn't break if undefined
                onChange={handleChange}
            />
        </Form.Group>
    </Col>
    <Col md={4}>
        <Form.Group controlId="emergencyContactName">
            <Form.Label>Contact Name</Form.Label>
            <Form.Control
                type="text"
                placeholder="Enter emergency contact name"
                name="emergencyContact.name"
                value={formData.emergencyContact?.name || ''}
                onChange={handleChange}
            />
        </Form.Group>
    </Col>
    <Col md={4}>
        <Form.Group controlId="emergencyContactRelation">
            <Form.Label>Relation</Form.Label>
            <Form.Control
                type="text"
                placeholder="Relation to emergency contact"
                name="emergencyContact.relation"
                value={formData.emergencyContact?.relation || ''}
                onChange={handleChange}
            />
        </Form.Group>
    </Col>
</Row>


{/* Relatives */}
<h4 className="mt-4">Relatives in Company</h4>
<Row>
  <Col md={3}>
    <Form.Group controlId="relativeName">
      <Form.Label>Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter relative's name"
        name="relatives.name"
        value={formData.professionalDetails.relatives[0]?.name || ''}
        onChange={(e) => handleArrayChange(0, 'name', e.target.value, 'relatives')}
      />
    </Form.Group>
  </Col>
  <Col md={3}>
    <Form.Group controlId="relativeDesignation">
      <Form.Label>Designation</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter designation"
        name="relatives.designation"
        value={formData.professionalDetails.relatives[0]?.designation || ''}
        onChange={(e) => handleArrayChange(0, 'designation', e.target.value, 'relatives')}
      />
    </Form.Group>
  </Col>
  <Col md={3}>
    <Form.Group controlId="relativeRelation">
      <Form.Label>Relation</Form.Label>
      <Form.Control
        type="text"
        placeholder="Relation to relative"
        name="relatives.relation"
        value={formData.professionalDetails.relatives[0]?.relation || ''}
        onChange={(e) => handleArrayChange(0, 'relation', e.target.value, 'relatives')}
      />
    </Form.Group>
  </Col>
  <Col md={3}>
    <Form.Group controlId="relativeContact">
      <Form.Label>Contact</Form.Label>
      <Form.Control
        type="text"
        placeholder="Relative's contact number"
        name="relatives.contact"
        value={formData.professionalDetails.relatives[0]?.contact || ''}
        onChange={(e) => handleArrayChange(0, 'contact', e.target.value, 'relatives')}
      />
    </Form.Group>
  </Col>
</Row>

{/* Bank Details */}
<h4 className="mt-4">Bank Details</h4>
<Row>
  <Col md={2}>
    <Form.Group controlId="bankName">
      <Form.Label>Bank Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter bank name"
        name="bankDetails.bankName"
        value={formData.professionalDetails.bankDetails[0]?.bankName || ''}
        onChange={(e) => handleArrayChange(0, 'bankName', e.target.value, 'bankDetails')}
      />
    </Form.Group>
  </Col>
  <Col md={2}>
    <Form.Group controlId="branchName">
      <Form.Label>Branch Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter branch name"
        name="bankDetails.branchName"
        value={formData.professionalDetails.bankDetails[0]?.branchName || ''}
        onChange={(e) => handleArrayChange(0, 'branchName', e.target.value, 'bankDetails')}
      />
    </Form.Group>
  </Col>
  <Col md={2}>
    <Form.Group controlId="accTitle">
      <Form.Label>Account Title</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter account title"
        name="bankDetails.accTitle"
        value={formData.professionalDetails.bankDetails[0]?.accTitle || ''}
        onChange={(e) => handleArrayChange(0, 'accTitle', e.target.value, 'bankDetails')}
      />
    </Form.Group>
  </Col>
   {/* Account Number */}
   <Col md={2}>
    <Form.Group controlId="accNo">
      <Form.Label>Account Number</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter account number"
        name="bankDetails.accNo"
        value={formData.professionalDetails.bankDetails[0]?.accNo || ''}
        onChange={(e) => handleArrayChange(0, 'accNo', e.target.value, 'bankDetails')}
      />
    </Form.Group>
  </Col>

  {/* IBAN */}
  <Col md={2}>
    <Form.Group controlId="iban">
      <Form.Label>IBAN</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter IBAN"
        name="bankDetails.iban"
        value={formData.professionalDetails.bankDetails[0]?.iban || ''}
        onChange={(e) => handleArrayChange(0, 'iban', e.target.value, 'bankDetails')}
      />
    </Form.Group>
  </Col>
</Row>


      

      {/* Expected Details */}
      <h4 className="mt-4">Expected Details</h4>
      <Row>
        <Col md={6}>
          <Form.Group controlId="expSalary">
            <Form.Label>Expected Salary</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter expected salary"
              name="expSalary"
              value={formData.professionalDetails.expSalary}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="expJoining">
            <Form.Label>Expected Joining Date</Form.Label>
            <Form.Control
              type="date"
              name="expJoining"
              value={formData.professionalDetails.expJoining}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
      <Col md={6}>
  <Form.Group controlId="onSite">
    <Form.Label>On-Site Preference</Form.Label>
    <Form.Control
      as="select"
      name="onSite"
      value={formData.professionalDetails.onSite ? 'true' : 'false'} 
      onChange={handleChange} 
    >
      <option value="true">Yes</option>
      <option value="false">No</option>
    </Form.Control>
  </Form.Group>
</Col>




        <Col md={6}>
          <Form.Group controlId="position">
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter desired position"
              name="position"
              value={formData.professionalDetails.position}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
      <Col md={6}>
        <Form.Group controlId="skills">
          <Form.Label>Skills</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your skills"
            name="skills"
            value={formData.professionalDetails.skills?.join(', ') || ''} 
            onChange={(e) => {
              const skillsArray = e.target.value.split(',').map(skill => skill.trim());
              setFormData(prevState => ({
                ...prevState,
                professionalDetails: {
                  ...prevState.professionalDetails,
                  skills: skillsArray, 
                }
              }));
            }}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
          <Form.Group controlId="portfolio">
            <Form.Label>Portfolio</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your portfolio links"
              name="portfolio"
              value={formData.professionalDetails.portfolio?.join(', ') || ''} 
              onChange={(e) => {
                const portfolioArray = e.target.value.split(',').map(link => link.trim()); 
                setFormData(prevState => ({
                  ...prevState,
                  professionalDetails: {
                    ...prevState.professionalDetails,
                    portfolio: portfolioArray, 
                  }
                }));
              }}
            />
          </Form.Group>
        </Col>
</Row>


      <Row>
      <Col md={6}>
          <Form.Group controlId="refs">
            <Form.Label>References</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter references"
              name="refs"
              value={formData.professionalDetails.refs?.join(', ') || ''} 
              onChange={(e) => {
                const refsArray = e.target.value.split(',').map(ref => ref.trim()); 
                setFormData(prevState => ({
                  ...prevState,
                  professionalDetails: {
                    ...prevState.professionalDetails,
                    refs: refsArray, 
                  }
                }));
              }}
            />
          </Form.Group>
        </Col>
        {/* <Col md={6}>
          <Form.Group controlId="questions">
            <Form.Label>Questions</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter any questions"
              name="questions"
              value={formData.questions}
              onChange={handleChange}
            />
          </Form.Group>
        </Col> */}
      </Row>


        {/* Submit */}
        <div className="text-center">
          <Button type="submit" className="mt-4" variant="primary">
            Update Information
          </Button>
          <Button type="button" className="mt-4 ms-2" variant="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
        </div>
      </Form>
    </div>
  );
}

export default UpdateEmployee;
