import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './LoginForm.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    newPassword: '',
    oldPassword: ''
  });
  const [isNewUser, setIsNewUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

 const handleSubmit = async (e) => {
    e.preventDefault();
    
   
    let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
    console.log('API URL:', apiUrl);  // Log the API URL to see what it's resolving to


    if (isNewUser) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
          `${apiUrl}/api/2/setPassword`,  // Use the dynamic URL
          {
            email: formData.email,
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        setErrorMessage('');
        alert('Password updated successfully');
        setIsNewUser(false); 
      } catch (error) {
        setErrorMessage('Error setting password. Please try again.');
      }
    } else {
      try {
        const response = await axios.post(`${apiUrl}/login`, {  // Use the dynamic URL
          email: formData.email,
          password: formData.password,
        });
  
        if (response.data.token && response.data.data.userTypeId) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userId', response.data.data._id);
  
          if (response.data.data.userTypeId === 1) {
            navigate('/admin/visitors');
          } else if (response.data.data.userTypeId === 2) {
            navigate('/employee/employeeData');
          } else {
            navigate('/dashboard');
          }
        } else {
          setErrorMessage('Invalid email or password');
        }
      } catch (error) {
        setErrorMessage('Error logging in. Please try again.');
      }
    }
  };

  return (
    <Container className="login-container">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h2 className="text-center login-title">{isNewUser ? 'Set Password' : 'Login'}</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {!isNewUser && (
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <div className="password-wrapper" style={{ position: 'relative' }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    value={formData.password}
                    onChange={handleChange}
                    required={!isNewUser}
                  />
                  <span onClick={togglePasswordVisibility} style={{
                      position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer'
                    }}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </Form.Group>
            )}

            {isNewUser && (
              <>
                <Form.Group controlId="formOldPassword" className="mb-3">
                  <Form.Label>Old Password</Form.Label>
                  <div className="password-wrapper" style={{ position: 'relative' }}>
                    <Form.Control
                      type={showOldPassword ? "text" : "password"}
                      name="oldPassword"
                      placeholder="Enter old password"
                      value={formData.oldPassword}
                      onChange={handleChange}
                      required
                    />
                    <span onClick={toggleOldPasswordVisibility} style={{
                        position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer'
                      }}>
                      {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </Form.Group>

                <Form.Group controlId="formNewPassword" className="mb-3">
                  <Form.Label>Set New Password</Form.Label>
                  <div className="password-wrapper" style={{ position: 'relative' }}>
                    <Form.Control
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      placeholder="Enter new password"
                      value={formData.newPassword}
                      onChange={handleChange}
                      required
                    />
                    <span onClick={toggleNewPasswordVisibility} style={{
                        position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer'
                      }}>
                      {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </Form.Group>
              </>
            )}

            {errorMessage && <p className="text-danger">{errorMessage}</p>}

            <Button variant="primary" type="submit" className="btn-block">
              {isNewUser ? 'Set Password' : 'Login'}
            </Button>

            <p className="mt-3 text-center">
              {isNewUser ? (
                <span onClick={() => setIsNewUser(false)} style={{ cursor: 'pointer', color: 'blue' }}>
                  Already have an account? Login
                </span>
              ) : (
                <span onClick={() => setIsNewUser(true)} style={{ cursor: 'pointer', color: 'blue' }}>
                  First time here? Set Password
                </span>
              )}
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
