import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, ListGroup, Button, Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import '../VisitorsDetails/VisitorsDetails.css';
import './EmployeeDetails.css'

const EmployeeDetails = () => {
  const { id } = useParams();
  const [employee, setEmployee] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
      

        const response = await fetch(`${apiUrl}/api/user/${id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (!response.ok) throw new Error('Failed to fetch employee details');
        const data = await response.json();
        setEmployee(data);
      } catch (error) {
        console.error('Error fetching employee details:', error);
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  const handleDownloadCV = async () => {
    try {
      const token = localStorage.getItem('token');
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 

    const response = await fetch(`${apiUrl}/api/user/cv/download`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userId: id })
    });

      if (!response.ok) throw new Error('Failed to download CV');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${employee.fullName}_CV.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error downloading CV:', error);
      alert('Error downloading CV: ' + error.message);
    }
  };

  const handleDownloadOtherDocs = async () => {
    try {
      const token = localStorage.getItem('token');
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 

    const response = await fetch(`${apiUrl}/api/user/otherDocs/download`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userId: id })
    });

      if (!response.ok) throw new Error('Failed to download documents');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${employee.fullName}_Documents.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error downloading documents:', error);
      alert('Error downloading documents: ' + error.message);
    }
  };

  if (!employee) return <h2>Loading...</h2>;

  return (
    <div className="visitor-details-container">
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="shadow-lg visitor-card">
              <Card.Header as="h3" className="visitor-header text-center">Employee Details</Card.Header>
              <Card.Body className="visitor-card-body">

                {/* Personal Details */}
                <section className="personal-details-section">
                  <h4 className="section-title">Personal Information</h4>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item><strong>Full Name:</strong> {employee.fullName}</ListGroup.Item>
                    <ListGroup.Item><strong>Father's Name:</strong> {employee.fatherName}</ListGroup.Item>
                    <ListGroup.Item><strong>Email:</strong> {employee.email}</ListGroup.Item>
                    <ListGroup.Item><strong>Mobile No:</strong> {employee.mobileNo}</ListGroup.Item>
                    <ListGroup.Item><strong>CNIC:</strong> {employee.cnic || 'N/A'}</ListGroup.Item>
                    <ListGroup.Item><strong>DOB:</strong> {moment(employee.dob).format('LL')}</ListGroup.Item>
                    <ListGroup.Item><strong>Gender:</strong> {employee.personalDetails?.gender}</ListGroup.Item>
                    <ListGroup.Item><strong>Marital Status:</strong> {employee.personalDetails?.maritalStatus}</ListGroup.Item>
                    <ListGroup.Item><strong>Religion:</strong> {employee.personalDetails?.religion}</ListGroup.Item>
                    <ListGroup.Item><strong>Blood Group:</strong> {employee.personalDetails?.bloodGroup}</ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Address:</strong> 
                      <div>Current: {employee.addresses?.current || 'N/A'}</div>
                      <div>Permanent: {employee.addresses?.permanent || 'N/A'}</div>
                    </ListGroup.Item>
                  </ListGroup>
                </section>

                {/* Emergency Contact */}
                <section className="emergency-contact-section mt-5">
                  <h4 className="section-title">Emergency Contact</h4>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item><strong>Name:</strong> {employee.emergencyContact?.name || 'N/A'}</ListGroup.Item>
                    <ListGroup.Item><strong>Number:</strong> {employee.emergencyContact?.number || 'N/A'}</ListGroup.Item>
                    <ListGroup.Item><strong>Relation:</strong> {employee.emergencyContact?.relation || 'N/A'}</ListGroup.Item>
                  </ListGroup>
                </section>

                {/* Professional Details */}
                <section className="personal-details-section">
                  <h4 className="section-title">Professional Details</h4>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item><strong>LinkedIn:</strong> <a href={employee.professionalDetails?.linkedIn} target="_blank" rel="noreferrer">LinkedIn</a></ListGroup.Item>
                    <ListGroup.Item><strong>GitHub:</strong> <a href={employee.professionalDetails?.gitHub} target="_blank" rel="noreferrer">GitHub</a></ListGroup.Item>
                    <ListGroup.Item><strong>Experience Years:</strong> {employee.professionalDetails?.expYears}</ListGroup.Item>
                  </ListGroup>
                </section>

                {/* Additional Details */}
                <section className="additional-details-section mt-5">
                  <h4 className="section-title">Additional Details</h4>

                  {/* Relatives */}
                  <h5 className="mt-3">Relatives:</h5>
                  {employee.professionalDetails?.relatives?.length ? (
                    employee.professionalDetails.relatives.map((relative, index) => (
                      <ListGroup.Item key={index}>
                        <strong>Name:</strong> {relative.name} <br />
                        <strong>Designation:</strong> {relative.designation} <br />
                        <strong>Relation:</strong> {relative.relation} <br />
                        <strong>Contact:</strong> {relative.contact}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>No relative details available.</ListGroup.Item>
                  )}

                  {/* Bank Details */}
                  <h5 className="mt-3">Bank Details:</h5>
                  {employee.professionalDetails?.bankDetails?.length ? (
                    employee.professionalDetails.bankDetails.map((bank, index) => (
                      <ListGroup.Item key={index}>
                        <strong>Bank Name:</strong> {bank.bankName} <br />
                        <strong>Branch Name:</strong> {bank.branchName} <br />
                        <strong>Account Title:</strong> {bank.accTitle} <br />
                        <strong>Account Number:</strong> {bank.accNo} <br />
                        <strong>IBAN:</strong> {bank.iban}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>No bank details available.</ListGroup.Item>
                  )}
                </section>

                {/* Previous Jobs */}
                <section className="previous-jobs-section mt-5">
                  <h4 className="section-title">Previous Jobs</h4>
                  {employee.professionalDetails?.prevJobs?.length ? (
                    employee.professionalDetails.prevJobs.map((job, index) => (
                      <ListGroup.Item key={index}>
                        <strong>Organization:</strong> {job.organization} <br />
                        <strong>Designation:</strong> {job.designation} <br />
                        <strong>Start Date:</strong> {moment(job.startDate).format('LL')} <br />
                        <strong>End Date:</strong> {moment(job.endDate).format('LL')} <br />
                        <strong>Reason for Leaving:</strong> {job.reasonForLeaving}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>No previous job details available.</ListGroup.Item>
                  )}
                </section>

                {/* Education */}
                <section className="education-section mt-5">
                  <h4 className="section-title">Education</h4>
                  {employee.professionalDetails?.education?.length ? (
                    employee.professionalDetails.education.map((edu, index) => (
                      <ListGroup.Item key={index}>
                        <strong>Degree:</strong> {edu.degree} <br />
                        <strong>Institute:</strong> {edu.institute} <br />
                        <strong>Passing Year:</strong> {moment(edu.passingYear).format('LL')} <br />
                        <strong>Grade:</strong> {edu.grade}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>No education details available.</ListGroup.Item>
                  )}
                </section>

                {/* Skills */}
                <section className="skills-section mt-5">
                  <h4 className="section-title">Skills</h4>
                  <ListGroup.Item>
                    {employee.professionalDetails?.skills?.length ? employee.professionalDetails.skills.join(', ') : 'No skills available'}
                  </ListGroup.Item>
                </section>

                {/* Expected Details */}
                <section className="expected-details-section mt-5">
                  <h4 className="section-title">Expected Details</h4>
                  <ListGroup.Item><strong>Expected Salary:</strong> {employee.professionalDetails?.expSalary}</ListGroup.Item>
                  <ListGroup.Item><strong>Expected Joining:</strong> {moment(employee.professionalDetails?.expJoining).format('LL')}</ListGroup.Item>
                  <ListGroup.Item><strong>OnSite Preference:</strong> {employee.professionalDetails?.onSite ? 'Yes' : 'No'}</ListGroup.Item>
                  <ListGroup.Item><strong>Position Applied for:</strong> {employee.professionalDetails?.position}</ListGroup.Item>
                  <ListGroup.Item><strong>References:</strong> {employee.professionalDetails?.refs.join(', ')}</ListGroup.Item>
                  <ListGroup.Item><strong>Portfolio:</strong> {employee.professionalDetails?.portfolio.join(', ')}</ListGroup.Item>
                </section>
{/* Download Last Degree Section */}
<section className="download-other-docs-section mt-5 p-3 text-center">
  <h4 className="section-title mb-3">Documents</h4>
  <p className="text-muted mb-4">Download a copy of the Documents for official records.</p>
  
  {/* Custom Styled Button */}
  <Button
    className="custom-download-btn btn-lg w-100 p-3 fw-bold shadow rounded-pill"
    onClick={handleDownloadOtherDocs}
    title="Download Documents"
  >
    Download Employee Documents
  </Button>
</section>



                {/* Action Buttons */}
 <div className="d-flex flex-column flex-md-row justify-content-between mt-4">
        <Button
          variant="primary"
          className="w-100 mb-2 mb-md-0 me-md-2 p-3 fw-bold border-0 shadow-sm"
          onClick={handleDownloadCV}
        >
          Download CV
        </Button>
        <Button
          variant="primary"
          className="w-100 p-3 fw-bold border-0 shadow-sm"
          onClick={() => navigate(-1)}>
    Go Back
        </Button>
      </div>



              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmployeeDetails;
