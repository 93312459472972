import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';

const EmployeesList = ({ handleApprove }) => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        
        const token = localStorage.getItem('token');
        
        if (!token) {
          throw new Error('No token found');
        }

        let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
       
        const response = await fetch(`${apiUrl}/api/users/2`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch visitors');
        }

        const data = await response.json();
        setEmployees(data.map(visitor => ({ ...visitor, disabled: false })));
      } catch (error) {
        console.error('Error fetching visitors:', error);
      }
    };

    fetchEmployees();
  }, []);

 
  

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to disable this visitor?");
    if (!confirmDelete) return;
  
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('No token found');
      }
  
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
    
      const response = await fetch(`${apiUrl}/api/user/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to disable visitor');
      }
  
    
      setEmployees((prevVisitors) => prevVisitors.filter(visitor => visitor._id !== id));
  
    } catch (error) {
      console.error('Error deleting visitor:', error);
    }
  };
  

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Employees List</h2>

      {/* Table for larger screens */}
      <div className="table-responsive d-none d-md-block">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Skills</th>
              <th>Experience (Years)</th>
              <th>Contact</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((visitor) => (
              <tr key={visitor._id}>
                <td>{visitor.fullName}</td>
                <td>{visitor.professionalDetails?.skills ? visitor.professionalDetails.skills.join(', ') : 'N/A'}</td>
                <td>{visitor.professionalDetails?.expYears}</td>
                <td>{visitor.mobileNo}</td>
                <td className="actions-cell">
  <Link to={`/admin/employeeDetails/${visitor._id}`}>
    <Button variant="primary" className="me-2">
      View Details
    </Button>
  </Link>
  <Link to={`/admin/addEmployee/${visitor._id}`}>
    <Button variant="secondary" className="me-2">
      Add Info
    </Button>
  </Link>
  <Button 
    variant="danger" 
    onClick={() => handleDelete(visitor._id)}
  >
    Disable
  </Button>
</td>

              </tr>                                                    
            ))}
          </tbody>
        </Table>
      </div>

      {/* Cards for mobile devices */}
      <div className="visitor-cards d-md-none">
        {employees.map((visitor) => (
          <div key={visitor._id} className="visitor-card mb-3">
            <h5>{visitor.fullName}</h5>
            <p><strong>Skills:</strong> {visitor.professionalDetails?.skills ? visitor.professionalDetails.skills.join(', ') : 'N/A'}</p>
            <p><strong>Experience:</strong> {visitor.professionalDetails?.expYears} years</p>
            <p><strong>Contact:</strong> {visitor.mobileNo}</p>
            <div className="visitor-actions">
            <Link to={`/admin/employeeDetails/${visitor._id}`}>
                <Button variant="primary" className="btn-view me-2 mb-2">
                  View Details
                </Button>
              </Link>
              <Link to={`/admin/addEmployee/${visitor._id}`}>
                  <Button variant="secondary" className="me-2">
                    Add Info
                  </Button>
                </Link>
              <Button 
                variant="warning" 
                className="mb-2" 
                onClick={() => handleDelete(visitor._id)}
              >
                Disable
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmployeesList;
