import React, { useState } from "react"; 
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import styles from './Visitor.module.css'

function JobApplicationForm() {
  const [formData, setFormData] = useState({
    fullName: '',  
    fatherName: '', 
    email: '', 
    password: '',  
    code: '',  
    userTypeId: '',  
    cnic: '',  
    mobileNo: '',  
    addresses: {
      current: '',
      permanent: ''
    }, 
    dob: '',  
    personalDetails: {
      gender: '',
      maritalStatus: '',
      religion: '',
      bloodGroup: '',
    }, 
    emergencyContact: {
      number: '',
      name: '',
      relation: ''
    },  
    isActive: true,  
    professionalDetails: {
      linkedIn: '',
      gitHub: '',
      cv: null,
      expYears: '',
      prevJobs: [{
        organization: '',
        designation: '',
        startDate: '',
        endDate: '',
        reasonForLeaving: ''
      }], 
      education: [{
        degree: '',
        institute: '',
        passingYear: '',
        grade: ''
      }],  
      bankDetails: [{
        bankName: '',
        branchName: '',
        accTitle: '',
        accNo: '',
        iban: ''
      }],
      expSalary: '',  
      expJoining: '',  
      onSite: false,  
      position: '',  
      skills: [],  
      portfolio: [], 
      refs: [],  
      questions: ''  
    }
  });
  const [showPassword, setShowPassword] = useState(false);
  const [cvFile, setCvFile] = useState(null);
  const [isCvSubmitting, setIsCvSubmitting] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCvUploaded, setIsCvUploaded] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'current' || name === 'permanent') {
      setFormData((prevState) => ({
        ...prevState,
        addresses: {
          ...prevState.addresses,
          [name]: value,
        },
      }));
      return;
    }

    if (['linkedIn', 'gitHub', 'expYears', 'expSalary', 'expJoining', 'position', 'skills', 'portfolio', 'refs', 'questions'].includes(name)) {
      setFormData((prevState) => ({
        ...prevState,
        professionalDetails: {
          ...prevState.professionalDetails,
          [name]: value,
        },
      }));
    } else if (name === 'onSite') {
      setFormData((prevState) => ({
        ...prevState,
        professionalDetails: {
          ...prevState.professionalDetails,
          onSite: value === 'true', 
        },
      }));
    } else if (name.startsWith('personalDetails.')) {
      const fieldName = name.split('.')[1];
      setFormData((prevState) => ({
        ...prevState,
        personalDetails: {
          ...prevState.personalDetails,
          [fieldName]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleArrayChange = (index, fieldName, value, arrayName) => {
    setFormData((prevState) => {
      const currentArray = prevState.professionalDetails[arrayName] || []; 

      const updatedArray = currentArray.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [fieldName]: value, 
          };
        }
        return item;
      });

      return {
        ...prevState,
        professionalDetails: {
          ...prevState.professionalDetails,
          [arrayName]: updatedArray, 
        },
      };
    });
  };

  const addPrevJobs = () => {
    setFormData((prevData) => ({
      ...prevData,
      professionalDetails: {
        ...prevData.professionalDetails,
        prevJobs: [...prevData.professionalDetails.prevJobs, { organization: '', designation: '', startDate: '', endDate: '', reasonForLeaving: '' }]
      }
    }));
  };

  const addEducation = () => {
    setFormData((prevData) => ({
      ...prevData,
      professionalDetails: {
        ...prevData.professionalDetails,
        education: [...prevData.professionalDetails.education, { degree: '', institute: '', pasingYear: '', grade: ''}]
      }
    }));
  };
  const handleFileChange = (e) => setCvFile(e.target.files[0]);
  const handleCvUpload = async () => {
    if (!cvFile) {
      alert("Please select a CV to upload.");
      return;
    }
  
    // Check file size (5MB limit)
    if (cvFile.size > 5 * 1024 * 1024) { // Limit to 5MB
      alert("File size exceeds 5MB. Please choose a smaller file.");
      return;
    }
  
    setIsCvSubmitting(true);
  
    const cvFormData = new FormData();
    cvFormData.append('pdf', cvFile);
  
    try {
      const token = localStorage.getItem('token');
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 

      const response = await fetch(`${apiUrl}/api/user/cv/upload/3`, {  // Use the dynamic URL
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,  // Pass the token if required for authentication
      },
      body: cvFormData
    });
  
      const responseBody = await response.json();
      if (response.ok) {
        setFormData((prevState) => ({
          ...prevState,
          professionalDetails: {
            ...prevState.professionalDetails,
            cv: responseBody.fileName
          }
        }));
        setIsCvUploaded(true);  // Mark CV as uploaded
        alert("CV uploaded successfully!");
      } else {
        alert("Failed to upload CV: " + (responseBody.message || responseBody));
      }
    } catch (error) {
      console.error("Error uploading CV:", error);
      alert("An error occurred during CV upload.");
    } finally {
      setIsCvSubmitting(false);
    }
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (emailError) {
      alert("Please correct the errors before submitting.");
      return;
    }
  
    if (!isCvUploaded) {
      alert("Please upload a CV before submitting the application.");
      return;
    }
  
    try {
      let apiUrl = process.env.REACT_APP_API_URL || 'http://82.112.255.51:3010'; 
    const response = await fetch(`${apiUrl}/api/user/3`, {  // Use the dynamic URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
  
      if (response.ok) {
        alert("Application submitted successfully!");
        setIsSubmitted(true);
      } else {
        const errorMessage = await response.text();
        alert("Failed to submit application: " + errorMessage);
      }
    } catch (error) {
      alert("An error occurred while submitting the application. Please try again.");
    }
  };
  
  
  
  return (
    <div className={`${styles.container} mt-5`}>
      <h2 className={`${styles.header} text-center mb-4`}>Job Application Form</h2>
      <div>
      {isSubmitted ? (
        <div className={styles.successMessage}>
          <h2>Your application is successfully submitted!</h2>
        </div>
      ) : (
      <Form onSubmit={handleSubmit}>
        {/* Personal Information */}
        <Row>
          <Col md={6}>
            <Form.Group controlId="fullName">
              <Form.Label>Full Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="fatherName">
              <Form.Label>Father's Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your father's name"
                name="fatherName"
                value={formData.fatherName}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Email *</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                isInvalid={!!emailError}
                required
              />
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>
            
          </Col>

          <Col md={6}>
            <Form.Group controlId="mobileNo">
              <Form.Label>Mobile No *</Form.Label>
              <Form.Control
                type="text"
                pattern="\d{11}"
                minLength="11"
                maxLength="11"
                placeholder="Enter your 11-digit mobile number"
                name="mobileNo"
                value={formData.mobileNo}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
      <Form.Group controlId="password" className="mb-4">
        <Form.Label>Password *</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            minLength="8"
          />
          <Button
            variant="outline-secondary"
            onClick={togglePasswordVisibility}
            style={{ borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }}
          >
            {showPassword ? "Hide" : "Show"}
          </Button>
        </InputGroup>
        <Form.Text className="text-muted" style={{ display: 'block', marginTop: '10px', marginBottom: '15px' }}>
          Password must be at least 8 characters long.
        </Form.Text>
        <Form.Text className="text-info" style={{ fontSize: '0.9rem', fontStyle: 'italic', display: 'block', marginBottom: '20px' }}>
          Remember this password for the next time.
        </Form.Text>
      </Form.Group>
    </Col>
        </Row>
        <Row>
 

        <Col md={6}>
          <Form.Group controlId="currentAddress">
            <Form.Label>Current Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter your current address"
              name="current" // Simplified name
              value={formData.addresses.current}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group controlId="permanentAddress">
            <Form.Label>Permanent Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter your permanent address"
              name="permanent" // Simplified name
              value={formData.addresses.permanent}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
          <Col md={6}>
            <Form.Group controlId="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Personal Details */}
         <h4 className="mt-4">Personal Details</h4>
        <Row>
          <Col md={3}>
            <Form.Group controlId="gender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                type="text"
                placeholder="Gender"
                name="personalDetails.gender"
                value={formData.personalDetails.gender}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="maritalStatus">
              <Form.Label>Marital Status</Form.Label>
              <Form.Control
                type="text"
                placeholder="Marital Status"
                name="personalDetails.maritalStatus"
                value={formData.personalDetails.maritalStatus}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="religion">
              <Form.Label>Religion</Form.Label>
              <Form.Control
                type="text"
                placeholder="Religion"
                name="personalDetails.religion"
                value={formData.personalDetails.religion}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
  
          <Col md={3}>
            <Form.Group controlId="bloodGroup">
              <Form.Label>Blood Group</Form.Label>
              <Form.Control
                type="text"
                placeholder="Blood Group"
                name="personalDetails.bloodGroup"
                value={formData.personalDetails.bloodGroup}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <h4 className="mt-4">Professional Details</h4>
<Row>
  <Col md={6}>
    <Form.Group controlId="linkedIn">
      <Form.Label>LinkedIn</Form.Label>
      <Form.Control
        type="text"
        placeholder="LinkedIn Profile"
        name="linkedIn" // Change the name
        value={formData.professionalDetails.linkedIn}
        onChange={handleChange}
      />
    </Form.Group>
  </Col>
  <Col md={6}>
    <Form.Group controlId="gitHub">
      <Form.Label>GitHub</Form.Label>
      <Form.Control
        type="text"
        placeholder="GitHub Profile"
        name="gitHub" 
        value={formData.professionalDetails.gitHub}
        onChange={handleChange}
      />
    </Form.Group>
  </Col>
  <Col md={6}>
    <Form.Group controlId="expYears">
      <Form.Label>Experience (in years)</Form.Label>
      <Form.Control
        type="number" 
        placeholder="Enter years of experience"
        name="expYears" 
        value={formData.professionalDetails.expYears}
        onChange={handleChange}
        min="0" 
      />
    </Form.Group>
  </Col>
 
 
</Row>

     
{/* Previous Jobs */}
<h4 className="mt-4">Previous Jobs</h4>
{formData.professionalDetails.prevJobs.map((job, index) => (
  <Row key={index}>
    <Col md={6}>
      <Form.Group controlId={`organization-${index}`}>
        <Form.Label>Previous Organization</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter previous organization"
          value={job.organization}
          onChange={(e) => handleArrayChange(index, 'organization', e.target.value, 'prevJobs')}
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`designation-${index}`}>
        <Form.Label>Designation</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your designation"
          value={job.designation}
          onChange={(e) => handleArrayChange(index, 'designation', e.target.value, 'prevJobs')}
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`startDate-${index}`}>
        <Form.Label>Start Date</Form.Label>
        <Form.Control
          type="date"
          value={job.startDate}
          onChange={(e) => handleArrayChange(index, 'startDate', e.target.value, 'prevJobs')}
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`endDate-${index}`}>
        <Form.Label>End Date</Form.Label>
        <Form.Control
          type="date"
          value={job.endDate}
          onChange={(e) => handleArrayChange(index, 'endDate', e.target.value, 'prevJobs')}
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`reasonForLeaving-${index}`}>
        <Form.Label>Reason for Leaving</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter reason for leaving"
          value={job.reasonForLeaving}
          onChange={(e) => handleArrayChange(index, 'reasonForLeaving', e.target.value, 'prevJobs')}
        />
      </Form.Group>
    </Col>
  </Row>
))}
<Button type="button" variant="secondary" onClick={addPrevJobs} className="mt-2">
  Add Other Previous Jobs
</Button>

{/* Educational Background */}
<h4 className="mt-4">Education</h4>
{formData.professionalDetails.education.map((education, index) => (
  <Row key={index}>
    <Col md={6}>
      <Form.Group controlId={`degree-${index}`}>
        <Form.Label>Degree</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your degree"
          value={education.degree}
          onChange={(e) => handleArrayChange(index, 'degree', e.target.value, 'education')}
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`institute-${index}`}>
        <Form.Label>Institute</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your institute"
          value={education.institute}
          onChange={(e) => handleArrayChange(index, 'institute', e.target.value, 'education')}
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`passingYear-${index}`}>
        <Form.Label>Passing Year</Form.Label>
        <Form.Control
          type="date"
          value={education.passingYear}
          onChange={(e) => handleArrayChange(index, 'passingYear', e.target.value, 'education')}
        />
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId={`grade-${index}`}>
        <Form.Label>Grade</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your grade"
          value={education.grade}
          onChange={(e) => handleArrayChange(index, 'grade', e.target.value, 'education')}
        />
      </Form.Group>
    </Col>
  </Row>
))}
<Button type="button" variant="secondary" onClick={addEducation} className="mt-2">
  Add another Education
</Button>

      

      {/* Expected Details */}
      <h4 className="mt-4">Expected Details</h4>
      <Row>
        <Col md={6}>
          <Form.Group controlId="expSalary">
            <Form.Label>Expected Salary</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter expected salary"
              name="expSalary"
              value={formData.professionalDetails.expSalary}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="expJoining">
            <Form.Label>Expected Joining Date</Form.Label>
            <Form.Control
              type="date"
              name="expJoining"
              value={formData.professionalDetails.expJoining}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
      <Col md={6}>
  <Form.Group controlId="onSite">
    <Form.Label>On-Site Preference</Form.Label>
    <Form.Control
      as="select"
      name="onSite"
      value={formData.professionalDetails.onSite ? 'true' : 'false'} 
      onChange={handleChange} 
    >
      <option value="true">Yes</option>
      <option value="false">No</option>
    </Form.Control>
  </Form.Group>
</Col>




        <Col md={6}>
          <Form.Group controlId="position">
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter desired position"
              name="position"
              value={formData.position}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
      <Col md={6}>
        <Form.Group controlId="skills">
          <Form.Label>Skills</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your skills"
            name="skills"
            value={formData.professionalDetails.skills?.join(', ') || ''} 
            onChange={(e) => {
              const skillsArray = e.target.value.split(',').map(skill => skill.trim());
              setFormData(prevState => ({
                ...prevState,
                professionalDetails: {
                  ...prevState.professionalDetails,
                  skills: skillsArray, 
                }
              }));
            }}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
          <Form.Group controlId="portfolio">
            <Form.Label>Portfolio</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your portfolio links"
              name="portfolio"
              value={formData.professionalDetails.portfolio?.join(', ') || ''} 
              onChange={(e) => {
                const portfolioArray = e.target.value.split(',').map(link => link.trim()); 
                setFormData(prevState => ({
                  ...prevState,
                  professionalDetails: {
                    ...prevState.professionalDetails,
                    portfolio: portfolioArray, 
                  }
                }));
              }}
            />
          </Form.Group>
        </Col>
</Row>


      <Row>
      <Col md={6}>
          <Form.Group controlId="refs">
            <Form.Label>References</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter references"
              name="refs"
              value={formData.professionalDetails.refs?.join(', ') || ''} 
              onChange={(e) => {
                const refsArray = e.target.value.split(',').map(ref => ref.trim()); 
                setFormData(prevState => ({
                  ...prevState,
                  professionalDetails: {
                    ...prevState.professionalDetails,
                    refs: refsArray, 
                  }
                }));
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="questions">
            <Form.Label>Questions</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter any questions"
              name="questions"
              value={formData.questions}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <h3 className="mt-4">Upload CV</h3>
        <Row>
          <Col md={6}>
            <Form.Group controlId="cvUpload">
              <Form.Label>Upload CV (PDF)</Form.Label>
              <Form.Control type="file" accept=".pdf" onChange={handleFileChange} />
            </Form.Group>
          </Col>
          <Col md={6} className="d-flex align-items-center">
            <Button variant="secondary" onClick={handleCvUpload} disabled={isCvSubmitting}>
              {isCvSubmitting ? 'Uploading...' : 'Upload CV'}
            </Button>
          </Col>
        </Row>


        {/* Submit */}
        <div className="text-center">
          <Button type="submit" className="mt-4" variant="primary">
            Submit Application
          </Button>
        </div>
      </Form>
       )}
    </div>
    </div>
  );
}

export default JobApplicationForm;
